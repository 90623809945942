import PropTypes from "prop-types";
import React from "react";
import Layout from "../components/Layout";
import LeftArrow from "../components/Arrows/Left-arrow";
import RightArrow from "../components/Arrows/Right-arrow";
import { Link } from "gatsby";

import TextAndImageBlock from "../components/BlogArticleSlices/TextAndImageBlock/textAndImageBlock";
import ArticleBlock from "../components/BlogArticleSlices/ArticleBlock/articleBlock";
import DualImageBlock from "../components/BlogArticleSlices/DualImageBlock/dualImageBlock";
import VideoBlock from "../components/FlexibleContentSlices/VideoPlayer/videoPlayer";

const BlogArticleTemplate = ({ pageContext, location }) => {
  const { data: templateData, prev, next } = pageContext;
  return (
    <Layout
      seoTitle={
        templateData.meta_title.text || templateData.title.text || "Page"
      }
      seoDescription={templateData.meta_description.text}
      location={location}
      hasMenuIcon
    >
      <div className="md:border md:border-primary-black-20 mt-hh max-w-2xl lg:max-w-3xl xl:max-w-5xl mx-auto py-8">
        {templateData.categories.slug && (
          <div className="tracking-widest uppercase font-light text-sm text-center flex justify-center mx-auto">
            <Link
              to={`/blog?category=${templateData.categories.slug}#category-section`}
            >
              <div className="py-1 border border-primary-black-20 rounded-full px-4">
                {templateData.categories.slug}
              </div>
            </Link>
          </div>
        )}

        {templateData.title && (
          <h1 className="text-center font-normal text-4xl mt-6">
            {templateData.title?.text}
          </h1>
        )}
        {templateData.subtitle && (
          <h3 className="mt-8 text-base md:text-lg lg:text-xl leading-relaxed text-center  mx-auto max-w-2xl 2xl:max-w-3xl px-ogs">
            {templateData.subtitle.text}
          </h3>
        )}
        {templateData.slices &&
          templateData.slices.map((slice, index) => {
            if (slice.slice_type === "article_block") {
              return <ArticleBlock key={index} sliceItems={slice.items} />;
            }
            if (slice.slice_type === "text_and_image_block") {
              return <TextAndImageBlock key={index} sliceItems={slice.items} />;
            }

            if (slice.slice_type === "dual_image_block") {
              return <DualImageBlock key={index} primary={slice.primary} />;
            }

            if (slice.slice_type === "video_block") {
              const firstItem =
                slice.items && slice.items.length > 0 ? slice.items[0] : {};
              return (
                <div className="p-gs">
                  <VideoBlock
                    key={index}
                    slice={{
                      primary: {
                        video: firstItem.video,
                        vimeo_url: firstItem.vimeo_url,
                        video_alignment: firstItem.video_alignment,
                        video_width: firstItem.video_width,
                        video_caption: firstItem.video_caption
                      }
                    }}
                  />
                </div>
              );
            }
            return null;
          })}
        <p className="mt-6 px-gs text-xs">{templateData.published_date}</p>
      </div>
      <div className="flex px-gs justify-between md:border border-primary-black-20 max-w-2xl lg:max-w-3xl xl:max-w-5xl mx-auto md:border-t-0 py-6 mb-32">
        {prev && (
          <Link
            className="flex items-center gap-x-3 pb-1 pt-2 link"
            to={prev.url}
          >
            <LeftArrow />
            <p className="text-base md:text-lg leading-relaxed">
              Previous article
            </p>
          </Link>
        )}
        {next && (
          <Link
            className="flex items-center gap-x-3 pb-1 pt-2 link"
            to={next.url}
          >
            <p className="text-base md:text-lg leading-relaxed">Next article</p>
            <RightArrow />
          </Link>
        )}
      </div>
    </Layout>
  );
};

BlogArticleTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default BlogArticleTemplate;
