import React from "react";
import Image from "gatsby-image";
import classNames from "classnames";
import { Link } from "gatsby";
import LinkIcon from "../../Link/LinkIcon";

const ArticleBlock = ({ sliceItems }) => {
  return (
    <div>
      {sliceItems &&
        sliceItems.map((item, index) => {
          let image_width = ["px-ogs"];
          if (item.image_width === "Wide") {
            image_width.push("md:px-0");
          }

          return (
            <div key={index}>
              {item.image?.fluid && (
                <div className={classNames(image_width, "mt-10")}>
                  <Image
                    placeholderStyle={{ display: "none" }}
                    fluid={{
                      ...item.image?.fluid,
                      aspectRatio: 1.8
                    }}
                    objectCover
                    loading="lazy"
                  />
                </div>
              )}
              <div className="px-gs">
                {item.subheading?.text && (
                  <h2 className="mt-10 font-normal text-2xl md:text-3xl">
                    {item.subheading.text}
                  </h2>
                )}
                {item.section_heading?.text && (
                  <h3 className="mt-8 text-xl md:text-2xl font-normal">
                    {item.section_heading.text}
                  </h3>
                )}
                {item.body1?.html && (
                  <div
                    className="mt-4 md:mt-6 text-base md:text-lg lg:text-xl leading-relaxed rte"
                    dangerouslySetInnerHTML={{
                      __html: item.body1.html
                    }}
                  />
                )}
                {item.link_text && item.link_text.text && item.link?.url && (
                  <Link
                    to={item.link?.url}
                    className="inline-flex gap-2 mt-8 items-center pt-2 link"
                  >
                    <div className="h-5 w-5">
                      <LinkIcon />
                    </div>
                    <p className="tracking-wider pb-1 uppercase font-medium">
                      {item.link_text.text}
                    </p>
                  </Link>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ArticleBlock;
